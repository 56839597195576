
import { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import A1_logo from '../assets/A1_logo.webp';

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);

  
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 768 && window.scrollY > 690) {
        setShowGoTop(true);
        setIsNavOpen(true);
      } else if (window.innerWidth > 768 && window.scrollY > 220) {
        setShowGoTop(true);
        setIsNavOpen(true);
      } else {
        setShowGoTop(false);
        setIsNavOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className={`navbar ${isNavOpen ? 'navopened' : ''}`}>
      <div className="container">
        <div className="row">
          <img
            style={{ width: '20%', height: '85px', objectFit: 'contain', cursor: 'pointer' }}
            src={A1_logo}
            alt="Logo"
          />
         

          
          <ul className={`${isNavOpen ? 'opened' : ''}`}>
            <li 
              className='menu'
            >
              <Link
                onClick={toggleNav}
                activeClass="active"
                spy={true}
                smooth={true}
                duration={1000}
                to="headerbg"
              >
                Home
              </Link>
            </li>
            <li
              className='menu'
            >
              <Link
                onClick={toggleNav}
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                duration={1000}
              >
                Services
              </Link>
            </li>
            <li
              className='menu'
            >
              <Link
                onClick={toggleNav}
                to="about-scroll"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                About
              </Link>
            </li>
            <li
              className='menu'
            >
              <Link
                onClick={toggleNav}
                to="contact"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="button" onClick={toggleNav}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>

    </nav>
  );
}

export default Navbar;
