
import A1 from '../assets/A1_wallpaperss.jpeg'


function Header() {
  return (
    <header>
       <div className="headerbg"></div>
      <div style={{position:"relative",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img style={{zIndex:'0',width:"100%",height:'100vh', objectFit:"cover"}} src={A1} alt="">
      </img>
        <span style={{position:"absolute",top:"230px",maxWidth:"60%",display:"flex",flexDirection:"column", alignItems:"center"}}>
          <a style={{color:"red",fontSize:"50px",fontWeight:"800"}}>
        TECHNOLOGY DESIGNED FOR YOU
      </a>
      <h1 >TOP PROFESSIONAL
      IT SOLUTIONS AND SERVICES</h1>
          </span>
        </div>

     
    </header>
  );
}
export default Header;
