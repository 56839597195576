function Card(props) {
  return (
    <div className="card">
      <div style={{minHeight:"300px"}}>

      <img style={{minHeight:"100%",minWidth:"100%",objectFit:"cover",borderRadius:"0.3rem"}} alt="card-img" src={props.img} className="text-center img-fluid" />
      </div>

      <a className="card-title">{props.title}</a>
    </div>
  );
}
export default Card;
