import about from '../assets/About.avif'

function About() {
  return (
    <>
      <div style={{marginTop:'0rem',width:'100%',height:'0px'}} className="about-scroll"></div>

      <div style={{width:"99vw",height:"70vh",display:"flex",justifyContent:"space-between",alignItems:"center",gap:"15px",boxSizing:"border-box",padding:"20px",marginTop:"120px"}}>
        <section style={{width:"50%",height:"100%",border:"2px"}}><img src={about} alt='' style={{objectFit:"contain",width:"100%",height:"100%"}}/> </section>
        <section style={{width:"100%",height:"100%",border:"2px ",paddingLeft:"15px",paddingRight:"17px"}}> <h2 className="main-title ">ABOUT</h2><p className="main-p">
            At A1 Network and IT Solutions, we are a dynamic IT enterprise committed to pioneering advanced technological solutions that propel business advancement. Our team of seasoned professionals excels in software engineering, cloud architecture, and robust cybersecurity strategies. We specialize in crafting scalable, secure, and bespoke solutions tailored to the distinct requirements of our clients. By harnessing the latest innovations, we empower organizations to thrive in an ever-evolving digital landscape. At the core of our mission lies an unwavering dedication to client satisfaction and the cultivation of enduring partnerships.
            </p></section>
        
      </div>
     
    </>
  );
}
export default About;
