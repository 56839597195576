import Card from "./Card";
import Web from '../assets/Web-dev.jpg'
import Android from '../assets/Android-dev.jpg'
import Ecommerce from '../assets/Ecommerce-dev.jpg'
import Digital from '../assets/Digital-marketing.jpg'
import Seo from '../assets/Seo-dev.png'
import Erp from '../assets/ERP.jpg'
import Bill from '../assets/Billing-dev.jpg'
import Network from '../assets/Networking-Solution-dev.png'
import Port from '../assets/Enterprise-Portal-Development.jpg'
import Social from '../assets/Social-media-marketing.jpg'



const services = [
    {
        title:'Web Design and Development',
        img:Web
    },
    {
        title:'Android App Development',
        img:Android
    },
    {
        title:'Digital Marketing',
        img:Digital
    },
    {
        title:'Ecommerce',
        img:Ecommerce
    },
    {
        title:'Search Engine Optimization (SEO)',
        img:Seo
    },
    {
        title:'ERP Development',
        img:Erp
    },
    {
        title:'Billing Software Development',
        img:Bill
    },
    {
        title:'Social Network Marketing',
        img:Social
    },
    {
        title:'Portal Development and Management',
        img:Port
    },
    {
        title:'Network and IT Solution',
        img:Network
    },

]
function Services() {
  return (

    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            
               
                    {
                        services.map((item,index)=><Card title={item.title} img={item.img}/>)
                    }
                
            
        </div>
    </div>
   
    
  );
}
export default Services;
